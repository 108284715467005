import React from 'react'
import Card from '@mui/material/Card'
import { useShoppingCart } from '../context/ShoppingCartContext'
import storeItems from '../data/items.json'
import formatCurrency from '../utility/formatCurrency'

const CartProduct = ({ id, quantity }) => {
    const { removeFromCart, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart()
    const item = storeItems.find(i => i.id === id)
    if (item == null) return null
    return (
        <Card className="product" square>
            <div className="image-box">
                <img src={item.productImg[0]} alt="Product" />
            </div>
            <div className="about">
                <h1 className="title">{item.name}</h1>
                <h3 className="subtitle">{formatCurrency(item.price)}</h3>
            </div>
            <div className="counter">
                <button
                    className="btn"
                    onClick={() => increaseCartQuantity(item.id)}
                >
                    <span>+</span>
                </button>
                <div className="count">{quantity}</div>
                <button
                    className="btn"
                    onClick={() => decreaseCartQuantity(item.id)}
                >
                    <span>-</span>
                </button>
            </div>
            <div className="prices">
                <div className="amount">{formatCurrency(item.price * quantity)}</div>
                <div>
                    <button className="remove" onClick={() => removeFromCart(id)}>Remove</button>
                </div>
            </div>
        </Card>
    )
}

export default CartProduct
