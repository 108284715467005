import { Fragment, useEffect } from 'react'
import StoreItem from './storeItem'
import storeItems from '../data/items.json'
import { useFavourites } from '../context/favouritesContext'
import Sidenav from './sideNav'
import CardNav from './cardNav'
import { FilterProvider, useFilter } from '../context/filterContext'

const MobileSideNav = () => {
    const { filtermenu } = useFilter()

    return filtermenu ? <Sidenav /> : null
}

const Products = () => {

    const { favItems } = useFavourites()

    return (
        <FilterProvider>
            <Fragment>
                <div className="products_container">
                    {
                        window.innerWidth > 768
                            ? <Sidenav />
                            : <MobileSideNav />
                    }
                    <div className="product_grid_container">
                        <CardNav />
                        <div className="products">
                            {
                                storeItems.map((item, index) => (
                                    <div className='card' key={index}>
                                        {
                                            favItems.find(items => items === item.id) ?
                                                <StoreItem {...item} fav={true} /> :
                                                <StoreItem {...item} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        </FilterProvider>
    )
}

export default Products