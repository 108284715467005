import { Fragment } from 'react'

import { ShoppingCartProvider } from './context/ShoppingCartContext'
import { FavouritesProvider } from './context/favouritesContext'
import { UserProvider } from './context/userContext'

import Route from './routes/route'

import './style.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function App() {

    return (
        <Fragment>
            <UserProvider>
                <ShoppingCartProvider>
                    <FavouritesProvider>
                        <Route />
                    </FavouritesProvider>
                </ShoppingCartProvider>
            </UserProvider>
        </Fragment>
    )
}

export default App
