import React, { Fragment } from 'react'

const System = () => {
    return (
        <Fragment>
            <div className="info-container">
                <div className="header">
                    <div className="title">System Integration</div>
                </div>
                <div className="info">
                    <div className="container">
                        GITS is a technology company that specializes in helping organizations with their technology needs. They offer solutions for custom applications, system analysis, and automation, and work with clients to reduce costs, increase productivity, and lower environmental impact. With their knowledge of engineering, information technology, and business control systems, GITS can design and implement sophisticated control systems, solutions, and services for businesses of all sizes. Working with GITS can help organizations achieve stronger business results, respond more rapidly to market changes, and transform both business and IT services.
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default System