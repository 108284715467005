import { useContext, createContext, useState } from "react"

const FilterContext = createContext({
    fil: [],
})

export function useFilter() {
    return useContext(FilterContext)
}

export function FilterProvider({ children }) {
    const [fil, setFil] = useState([])
    const [filtermenu, setFiltermenu] = useState(false)

    const togglefilter = (text) => {
        setFil(currItems => {
            if (currItems.find((item) => item === text) == null) {
                return [...currItems, text]
            } else {
                return currItems.filter((item) => item !== text)
            }
        })
    }

    const clearfilter = () => {
        setFil([])
    }

    return (
        <FilterContext.Provider value={{
            togglefilter,
            clearfilter,
            setFiltermenu,
            fil,
            filtermenu
        }}>
            {children}
        </FilterContext.Provider>
    )
}