import React from 'react'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import formatCurrency from '../utility/formatCurrency'
import { Link } from 'react-router-dom'
import { Rating } from '@mui/material'
import { FaStar } from 'react-icons/fa'
import { useShoppingCart } from '../context/ShoppingCartContext'
import { useFavourites } from '../context/favouritesContext'

const StoreItem = ({ id, name, price, rating, productImg, fav }) => {
    const { increaseCartQuantity } = useShoppingCart()
    const { toggleFav } = useFavourites()
    return (
        <div className="product">
            <div
                className="wish"
                style={{ opacity: fav ? 1 : null }}
            >
                <button onClick={() => toggleFav(id)}>
                    {fav ? (
                        <BsHeartFill
                            style={{ fill: fav ? '#F63528' : null }}
                        />
                    ) : (
                        <BsHeart />
                    )}
                </button>
            </div>
            <Link to={`/product/${id}`} className="image">
                <img src={productImg[0]} alt="Product" />
            </Link>
            <div className="contents">
                <Link to={`/product/${id}`} className="desc">
                    <span>
                        {name}
                    </span>
                </Link>
                <div className="more">
                    <Rating
                        className="ratings"
                        defaultValue={parseInt(rating)}
                        precision={0.5}
                        icon={<FaStar className='icon selected' />}
                        emptyIcon={<FaStar className='icon unselected' />}
                        readOnly
                    />
                    <span className="price">{formatCurrency(price)}</span>
                    <button className="buy" onClick={() => increaseCartQuantity(id)}>
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    )
}

export default StoreItem
