import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from '../components/navigation'
import Footer from '../components/footer'
import ScrollToTop from '../components/scrollToTop'

const PublicLayout = () => {
    return (
        <Fragment>
            <ScrollToTop />
            <Navigation />
            <Outlet />
            <Footer />
        </Fragment>
    )
}

export default PublicLayout