import { Fragment } from 'react'
import { useFilter } from '../context/filterContext'


const CardNav = () => {

    const { fil, clearfilter, togglefilter, setFiltermenu, filtermenu } = useFilter()

    return (
        <Fragment>
            {
                window.innerWidth > 768 ? (
                    <div className="top_nav">
                        {
                            window.innerWidth < 768 ?
                                (
                                    <button className="menu" onClick={() => setFiltermenu(!filtermenu)}>
                                        Filter
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 96 960 960" width="20px"><path d="M427 936V711h60v83h353v60H487v82h-60Zm-307-82v-60h247v60H120Zm187-166v-82H120v-60h187v-84h60v226h-60Zm120-82v-60h413v60H427Zm166-165V216h60v82h187v60H653v83h-60Zm-473-83v-60h413v60H120Z" /></svg>
                                    </button>
                                ) : null
                        }
                        {
                            fil.map(item => (
                                <div className="chip secondary">
                                    {item}
                                    <button className="icon" onClick={() => togglefilter(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                                    </button>
                                </div>
                            ))
                        }
                        {
                            fil.length !== 0 ?
                                (
                                    <button className="chip primary" onClick={clearfilter}>
                                        Clear All
                                    </button>
                                ) : null
                        }
                    </div>
                ) : null
            }
        </Fragment>
    )
}

export default CardNav