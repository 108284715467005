import React, { Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, A11y } from 'swiper'
import 'swiper/css/pagination'
import storeItems from '../data/items.json'
import StoreItem from './storeItem'
import { useFavourites } from '../context/favouritesContext'
import { Link, useNavigate } from 'react-router-dom'
const Home = () => {

    const { favItems } = useFavourites()

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/product/00eb26dae5a`;
        navigate(path);
    }

    return (
        <Fragment>
            <div className='offers'>
                <div className='back'>
                    <div className="filter" />
                </div>
            </div>
            <div className='features'>
                <Link to={'/products'} className='category'>
                    <div className='img_container'>
                        <img src='./images/office-chair.png' alt='Category' />
                    </div>
                    <div className='desc'><span>Components</span></div>
                </Link>
                <Link to={'/products'} className='category'>
                    <div className='img_container'>
                        <img src='./images/laptop.png' alt='Category' />
                    </div>
                    <div className='desc'><span>Laptops</span></div>
                </Link>
                <Link to={'/products'} className='category'>
                    <div className='img_container'>
                        <img src='./images/headphones.png' alt='Category' />
                    </div>
                    <div className='desc'><span>Accessories</span></div>
                </Link>
                <Link to={'/products'} className='category'>
                    <div className='img_container'>
                        <img src='./images/computer.png' alt='Category' />
                    </div>
                    <div className='desc'><span>Desktops</span></div>
                </Link>
            </div>
            <div className='store_banner'>
                <div className='store'>
                    <div className='title'>Featured Products</div>
                    <div className='product_swiper product-swiper-one'>
                        <Swiper
                            className='swiper-wrapper'
                            breakpoints={{
                                100: {
                                    // width: 100,
                                    slidesPerView: 2,
                                    slidesPerGroup: 1,
                                    spaceBetween: 20,
                                    centeredSlides: true
                                }, 768: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                    spaceBetween: 40
                                }
                            }}
                            modules={[Pagination, A11y]}
                            direction='horizontal'
                            speed={400}
                            loop={false}
                            slidesPerView={4}
                            slidesPerGroup={4}
                            spaceBetween={40}

                            pagination={
                                {
                                    el: '.swiper-pagination-one',
                                    type: 'bullets',
                                    clickable: 'true',
                                    dynamicBullets: 'true',
                                    dynamicMainBullets: 3,
                                }
                            }

                        >
                            {storeItems.map(item => (
                                <SwiperSlide className='swiper-slide' key={item.id}>
                                    {
                                        favItems.find(items => items === item.id) ?
                                            <StoreItem {...item} fav={true} /> :
                                            <StoreItem {...item} />
                                    }
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className='swiper-pagination swiper-pagination-one'></div>
                    </div>
                </div>
            </div>
            <div className='ad_container'>
                <div className='ad'>
                    <div className='left'>
                        <div className='title'>Keychron K8 Keyboard</div>
                        <div className='desc'>Engineered to maximize your productivity with most popular TKL layout.</div>
                        <button className='btn' onClick={routeChange}>View</button>
                    </div>
                    <div className='right'>
                        <img src='./images/507a7748cf1af6b126a0e46212fcfb1f-removebg-preview.png' alt='ad' />
                    </div>
                </div>
            </div>
            <div className='store_banner'>
                <div className='store'>
                    <span className='title'>Best Selling Products</span>
                    <div className='product_swiper product-swiper-two'>
                        <Swiper
                            className='swiper-wrapper'
                            breakpoints={{
                                100: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 1,
                                    spaceBetween: 20,
                                    centeredSlides: true
                                }, 768: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                    spaceBetween: 40
                                }
                            }}
                            modules={[Pagination, A11y]}
                            direction='horizontal'
                            speed={400}
                            loop={false}
                            slidesPerView={4}
                            slidesPerGroup={4}
                            spaceBetween={40}

                            pagination={
                                {
                                    el: '.swiper-pagination-two',
                                    type: 'bullets',
                                    clickable: 'true',
                                    dynamicBullets: 'true',
                                    dynamicMainBullets: 3,
                                }
                            }
                        >
                            {storeItems.map(item => (
                                <SwiperSlide className='swiper-slide' key={item.id}>
                                    {
                                        favItems.find(items => items === item.id) ?
                                            <StoreItem {...item} fav={true} /> :
                                            <StoreItem {...item} />
                                    }
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className='swiper-pagination swiper-pagination-two'></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Home