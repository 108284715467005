import React, { Fragment } from 'react'

const Software = () => {
    return (
        <Fragment>
            <div className="info-container">
                <div className="header">
                    <div className="title">Software</div>
                </div>
                <div className="info">
                    <div className="container">
                        GITS creates applications, whether web, cloud, mobile, or desktop based on a person’s idea or by providing a solution based on a specification. Specifications can besupplied to GITS to follow or GITS can create a specification formulating a GITS proposal for a particular task. Ideas need solutions and it can be a challenge at times to put all the pieces of an idea together to formulate a solution that is not just viable but also productive and achieves the goal of what it is designed to do. Putting the puzzle pieces together is where GITS come in, they take the idea, or ideas and formulate them into a complete solution for the target platform. GITS have successfully assisted people with bringing their ideas to life which then allows them to form a viable business using the products we developed for them. Some have gone on to be worldwide best selling products in their respected fields.
                        <br />
                        <br />
                        GITS have developed software including programs that help with building Tunnels, Real Time Data Collection, Location Aware Data Monitoring, Database Systems And Reporting, SMS Systems, SQL Database Solutions, Tablet Based Software, Information Management Systems, Service Management, Artificial Intelligence Based Applications, Reporting Services, Mail Servers, Spam Control Systems, Accounting And Financial Solutions, Packaging And Labeling Applications used in companies and many more. GITS don't just stop with development they also help clients with business management, implementation, training, I.T. and other requirements they may have, including assisting with marketing and social networking tips, tricks and skills.
                        <div className="sub_container">
                            <div className="sub_title">How we do it</div>
                            <div className="desc">GITS specializes in developing software and web solutions for clients using the latest development technologies. They create a proposal after meeting with the client to understand their requirements and provide costing, user interface overviews, program flows, class definitions, and database designs. GITS believes in developing solutions with a solid foundation for future growth and breaks each project into steps, including business modeling, coding, initial implementation, testing, and deployment. They employ the right tools for each job, which are always mainstream tools, to ensure clients can easily find developers to work on their project in the future. GITS mainly uses Microsoft Visual Studio for ASP .NET and .NET Solutions and programming in C#, but also has experience using other technologies such as PHP, mySQL, JAVA, Flash, and more.</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Software