import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'

const Header = () => {
    return (
        <Fragment>
            <Helmet>
                <title>G-Five Technologies Pvt. Ltd.</title>
            </Helmet>
            <Outlet />
        </Fragment>
    )
}

export default Header