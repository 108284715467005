import React, { Fragment } from 'react'
import { useFavourites } from '../../context/favouritesContext'
import { useShoppingCart } from '../../context/ShoppingCartContext'
import StoreItems from '../../data/items.json'

const Wish = () => {
    const { favItems, toggleFav } = useFavourites()
    const { increaseCartQuantity } = useShoppingCart()

    return (
        <Fragment>
            <div className='account-desc'>
                <div className='title'>Wishlist</div>
                <div className='container wish_cont'>
                    {
                        StoreItems.map(items => (
                            <div key={items.id}>
                                {
                                    favItems.some(f => items.id === f) ?
                                        <div className='wish_container'>
                                            {
                                                <Fragment>
                                                    <div className="img_container">
                                                        <img src={items.productImg[0]} alt='product' />
                                                    </div>
                                                    <div className="desc">
                                                        <div className="title">{items.name}</div>
                                                        <div className="buttons">
                                                            <button onClick={() => increaseCartQuantity(items.id)} className="btn primary">Add to Cart</button>
                                                            <button onClick={() => toggleFav(items.id)} className="btn secondary">Remove from List</button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                        </div> : null
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Wish