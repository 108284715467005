import { useRoutes, Navigate } from 'react-router-dom'

import PublicLayout from '../layout/PublicLayout'
import Wish from '../components/accounts/wish'
import Orders from '../components/accounts/orders'
import ShippingInfo from '../components/accounts/shippingInfo'
import BillingInfo from '../components/accounts/billingInfo'
import AccountInfo from '../components/accounts/accountInfo'
import Home from '../components/home'
import About from '../components/about'
import Software from '../components/software'
import System from '../components/system'
import Error from '../components/error'
import SignIn from '../components/SignIn'
import SingleProduct from '../components/singleProduct'
import Contact from '../components/contact'
import Products from '../components/products'
import Account from '../components/account'
import Signup from '../components/SignUp'
import Services from '../components/services'
import Unauthorized from '../components/unauthorized'
import RequireAuth from '../components/RequireAuth'
import PersistLogin from '../components/PersistLogin'
import Header from '../components/header'

const Route = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <Header />,
            children: [
                {
                    element: <PublicLayout />,
                    children: [
                        {
                            element: <PersistLogin />,
                            children: [
                                {
                                    element: <RequireAuth />,
                                    children: [
                                        {
                                            path: '/account',
                                            element: <Account />,
                                            children: [
                                                {
                                                    index: true,
                                                    element: <AccountInfo />
                                                },
                                                {
                                                    path: 'shippingInfo',
                                                    element: <ShippingInfo />
                                                },
                                                {
                                                    path: 'billingInfo',
                                                    element: <BillingInfo />
                                                },
                                                {
                                                    path: 'orders',
                                                    element: <Orders />
                                                },
                                                {
                                                    path: 'wish',
                                                    element: <Wish />
                                                },
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            index: true,
                            element: <Home />
                        },
                        {
                            path: '/contact',
                            element: <Contact />
                        },
                        {
                            path: '/about',
                            element: <About />
                        },
                        {
                            path: '/products',
                            element: <Products />
                        },
                        {
                            path: '/services',
                            element: <Services />
                        },
                        {
                            path: '/software',
                            element: <Software />
                        },
                        {
                            path: '/system_integration',
                            element: <System />
                        },
                        {
                            path: '/product',
                            children: [
                                {
                                    path: ':id',
                                    element: <SingleProduct />
                                }
                            ]
                        },
                        {
                            path: '*',
                            element: <Navigate to={'/404'} replace={true} />
                        }
                    ]
                },
                {
                    path: 'signup',
                    element: <Signup />
                },
                {
                    path: 'signin',
                    element: <SignIn />
                },
                {
                    path: 'unauthorized',
                    element: <Unauthorized />
                },
                {
                    path: '/404',
                    element: <Error />
                },
            ]
        }

    ])

    return routes
}

export default Route