import { useContext, createContext, useReducer } from "react"
import { reducer, initialState } from '../reducer/useReducer'

const UserContext = createContext({})

export function useUser() {
    return useContext(UserContext)
}

export function UserProvider({ children }) {

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <UserContext.Provider value={{ state, dispatch }}>
            {children}
        </UserContext.Provider>
    )
}