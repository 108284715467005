import React, { Fragment, useState } from 'react'
import { Rating } from '@mui/material'
import { FaStar } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import { useShoppingCart } from '../context/ShoppingCartContext'
import { useFavourites } from '../context/favouritesContext'
import { BsHeart, BsHeartFill } from 'react-icons/bs'
import formatCurrency from '../utility/formatCurrency'
import storeItems from '../data/items.json'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import { Helmet } from 'react-helmet'

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import Slider from './slider'

const SingleProduct = () => {

    const { increaseCartQuantity } = useShoppingCart()
    const { id } = useParams()
    const product = storeItems.find((product) => product.id === id)

    const { toggleFav, favItems } = useFavourites()

    const [productImage, setProductImage] = useState()

    const location = window.location.href

    return (
        <Fragment>
            <Helmet>
                <title>{product.name}</title>
                <meta property="og:title" content={product.name} />
                <meta property="og:image" content={product.productImg[0]} />
                <meta property="og:url" content={location} />
            </Helmet>
            <div className='product-page-container'>
                <div className='product'>
                    <div className='preview'>
                        {
                            window.innerWidth < 768 ? (
                                <div className="mobile_title">
                                    {product.name}
                                </div>
                            ) : null
                        }
                        <Swiper
                            loop={true}
                            spaceBetween={10}
                            thumbs={{ swiper: productImage }}
                            modules={[FreeMode, Thumbs]}
                            className="product-image-swiper"
                        >
                            {
                                product.productImg.map((items, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={items} alt="product_img" />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <Swiper
                            onSwiper={setProductImage}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="product-image-thumb-swiper"
                        >
                            {
                                product.productImg.map((items, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="pane">
                                            <img src={items} alt="thumb_product_img" />
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                    <div className='desc'>
                        {
                            window.innerWidth > 768 ? (
                                <div className='title'>
                                    {product.name}
                                </div>
                            ) : null
                        }
                        <div className='info'>
                            <div className='rating-container'>
                                <Rating
                                    className="ratings"
                                    defaultValue={parseInt(product.rating)}
                                    precision={0.5}
                                    icon={<FaStar className='icon selected' />}
                                    emptyIcon={<FaStar className='icon unselected' />}
                                    readOnly
                                />
                                <div className='wish'>
                                    <button onClick={() => toggleFav(id)}>
                                        {
                                            favItems.find(items => items === id) ?
                                                <BsHeartFill style={{ fill: '#F63528' }} /> :
                                                <BsHeart />
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='price'>{formatCurrency(product.price)}</div>
                        <div className='container'>
                            <div className="stock">
                                <div className="ico"></div>
                                <span>In Stock</span>
                            </div>
                        </div>
                        <div className='checkouts'>
                            <button type='submit' className='buy primary'>
                                Buy Now
                            </button>
                            <button type='submit' className='add_cart secondary' onClick={() => increaseCartQuantity(id)}>
                                Add to Cart
                            </button>
                        </div>
                    </div>
                    {/* <div className='details'>
                        <div className='delivery'>
                            <div className='header'>
                                <div className='title'>Delivery</div>
                                <div className='info'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        id='Outline'
                                        viewBox='0 0 24 24'
                                        width='12'
                                        height='12'
                                    >
                                        <path d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z' />
                                        <path d='M12,10H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0V12A2,2,0,0,0,12,10Z' />
                                        <circle cx='12' cy='6.5' r='1.5' />
                                    </svg>
                                </div>
                            </div>
                            <div className='location'>
                                <div className='logo'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        id='Outline'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                    >
                                        <path d='M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z' />
                                        <path d='M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z' />
                                    </svg>
                                </div>
                                <div className='info'>
                                    Bagmati Province, Kathmandu Metro 8 -
                                    Gaushala Area, Gaushala
                                </div>
                                <div className='sub_info'>
                                    <Link href='#'>change</Link>
                                </div>
                            </div>
                            <div className='home-delivery'>
                                <div className='logo'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        id='Layer_1'
                                        data-name='Layer 1'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                    >
                                        <path d='M19,5H17V4a3,3,0,0,0-3-3H3A3,3,0,0,0,0,4V19H2.041A3.465,3.465,0,0,0,2,19.5a3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5h6.082a3.465,3.465,0,0,0-.041.5,3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5H24V10A5.006,5.006,0,0,0,19,5Zm0,2a3,3,0,0,1,3,3v1H17V7ZM7,19.5a1.5,1.5,0,0,1-3,0,1.418,1.418,0,0,1,.093-.5H6.907A1.418,1.418,0,0,1,7,19.5ZM15,17H2V4A1,1,0,0,1,3,3H14a1,1,0,0,1,1,1Zm5,2.5a1.5,1.5,0,0,1-3,0,1.41,1.41,0,0,1,.093-.5h2.814A1.41,1.41,0,0,1,20,19.5ZM17,17V13h5v4Z' />
                                    </svg>
                                </div>
                                <div className='info'>
                                    Home Delivery
                                    <p>2 - 3 days</p>
                                </div>
                                <div className='sub_info'>Rs. 100</div>
                            </div>
                            <div className='cash-on-delivery'>
                                <div className='logo'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        id='Layer_1'
                                        data-name='Layer 1'
                                        viewBox='0 0 24 24'
                                        width='20'
                                        height='20'
                                    >
                                        <path d='M21,6H5c-.859,0-1.672-.372-2.235-.999,.55-.614,1.349-1.001,2.235-1.001H23c.553,0,1-.448,1-1s-.447-1-1-1H5C2.239,2,0,4.239,0,7v10c0,2.761,2.239,5,5,5H21c1.657,0,3-1.343,3-3V9c0-1.657-1.343-3-3-3Zm1,13c0,.551-.448,1-1,1H5c-1.654,0-3-1.346-3-3V6.998c.854,.639,1.904,1.002,3,1.002H21c.552,0,1,.449,1,1v10Zm-2-5c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z' />
                                    </svg>
                                </div>
                                <div className='info'>
                                    Cash on Delivery Available
                                </div>
                            </div>
                        </div>
                        <div className='services'>
                            <div className='header'>
                                <div className='title'>Services</div>
                                <div className='info'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        id='Outline'
                                        viewBox='0 0 24 24'
                                        width='12'
                                        height='12'
                                    >
                                        <path d='M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z' />
                                        <path d='M12,10H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0V12A2,2,0,0,0,12,10Z' />
                                        <circle cx='12' cy='6.5' r='1.5' />
                                    </svg>
                                </div>
                            </div>
                            <div className='return'>
                                <div className='logo'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 656q33 0 56.5-23.5T560 576q0-33-23.5-56.5T480 496q-33 0-56.5 23.5T400 576q0 33 23.5 56.5T480 656Zm0 236q41-11 81.5-40.5T632 786l-72-72q-18 11-38.5 16.5T480 736q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618 658l60 60q20-41 31-86t11-92V351l-240-90-240 90v189q0 121 68 220t172 132Zm0 84q-139-35-229.5-159.5T160 540V296l320-120 320 120v244q0 152-90.5 276.5T480 976Zm8-400Z" /></svg>
                                </div>
                                <div className='info'>
                                    Seven Days Return
                                    <p>Change your mind if not applicable</p>
                                </div>
                            </div>
                            <div className='warranty'>
                                <div className='logo'>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="m438 718 226-226-57-57-169 169-84-84-57 57 141 141Zm42 258q-139-35-229.5-159.5T160 540V296l320-120 320 120v244q0 152-90.5 276.5T480 976Zm0-84q104-33 172-132t68-220V351l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z" /></svg>
                                </div>
                                <div className='info'>1 Year Warranty</div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='product-details'>
                <span className='title'>Product Details</span>
                <div className='desc'>
                    {
                        product.desc.map((items, ind) => (
                            <div key={ind}>
                                <p>{items[0]}</p>
                                <span>{items[1]}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='feedback-container'>
                <div className='customer-ratings'>
                    <span className='title'>Product Ratings</span>
                    <div className='rating-details'>
                        <div className='ratings'>
                            <div className='desc'>
                                {product.rating}
                                <span>
                                    /5
                                </span>
                            </div>
                            <Rating
                                className="rating"
                                defaultValue={parseInt(product.rating)}
                                precision={0.5}
                                icon={<FaStar className='icon selected' />}
                                emptyIcon={<FaStar className='icon unselected' />}
                                readOnly
                            />
                            <div className='rate'>{product.comments.length} Ratings</div>
                        </div>
                        <div className='ratings-desc'>
                            <Slider width={30} txt={5} />
                            <Slider width={50} txt={4} />
                            <Slider width={70} txt={3} />
                            <Slider width={80} txt={2} />
                            <Slider width={10} txt={1} />
                        </div>
                    </div>
                </div>
                <div className='customer-reviews'>
                    <span className='title'>Product Reviews</span>
                    <div className='reviews'>
                        {
                            product.comments.map((items, index) => (
                                <div className='review-container' key={index}>
                                    <div className='image-container'>
                                        <img src={items.image} alt='product' />
                                    </div>
                                    <div className='chat'>
                                        <div className='review-data'>
                                            <div className='review-top'>
                                                <Rating
                                                    className="ratings"
                                                    defaultValue={items.rate}
                                                    precision={0.5}
                                                    icon={<FaStar className='icon selected' />}
                                                    emptyIcon={<FaStar className='icon unselected' />}
                                                    readOnly
                                                />
                                                <div className='date'>{items.msg_date}</div>
                                            </div>
                                            <div className='name'>{items.user}</div>
                                            <div className='desc'>
                                                {items.msg}
                                            </div>
                                        </div>
                                        <div className='response'>
                                            <div className='response-top'>
                                                <div className='name'>
                                                    Global IT Pvt. Ltd.
                                                </div>
                                                <div className='date'>{items.reply_date}</div>
                                            </div>
                                            <div className='desc'>{items.reply}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default SingleProduct
